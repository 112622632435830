import { useState, useEffect } from "react";
import { Squash as Hamburger } from "hamburger-react";

function NavBar() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const isActive = (pathname) => window.location.pathname === pathname;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const Where = (hash) => window.location.hash === hash;
  const handleScroll = () => {
    window.scrollTo({
      top: 700,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    if (Where("#works")) {
      if (window.innerWidth > 990){
        window.scrollTo({
          top: 700,
          behavior: "smooth",
        });
      }
    }
  }, [Where]);
  return (
    <nav className="bg-transparent w-full absolute top-0 z-50">
      <div className="px-[16px] lg:px-[70px] my-[15px] lg:my-[30px] mx-auto flex justify-between items-center">
        <a href="/" className="flex items-center">
          <span
            className={`font-islandMoments ${
              isActive("/") || isActive("/home") ? "text-black" : "text-white"
            } text-[32px] lg:text-[36px] font-[400] leading-normal`}
          >
            Shrill
          </span>
        </a>
        <div className="hidden lg:flex items-center gap-x-[60px] font-medium">
          <p
            className={`cursor-pointer font-inter ${
              isActive("/") || isActive("/home")
                ? "text-black"
                : "text-white hidden"
            } hover:font-bold hover:scale-[1.05] transition-all duration-100 text-[16px] font-[400] leading-normal tracking-[4.48px]`}
            onClick={handleScroll}
          >
            EXPERIENCE DESIGN
          </p>
          <a
            href="/#works"
            className={`cursor-pointer font-inter ${
              isActive("/") || isActive("/home")
                ? "text-black hidden"
                : "text-white"
            } hover:font-bold hover:scale-[1.05] transition-all duration-100 text-[16px] font-[400] leading-normal tracking-[4.48px]`}
          >
            EXPERIENCE DESIGN
          </a>
          <a
            href="/graphic"
            className={`cursor-pointer font-inter ${
              isActive("/") || isActive("/home") ? "text-black" : "text-white"
            } hover:font-bold hover:scale-[1.05] transition-all duration-100 text-[16px] font-[400] leading-normal tracking-[4.48px] ${
              isActive("/graphic") ? "font-bold" : ""
            }`}
          >
            GRAPHIC
          </a>
          <a
            href="https://www.instagram.com/freezing_pixels/?hl=en"
            target="__blank"
            rel="noreferrer"
            className={`cursor-pointer font-inter ${
              isActive("/") || isActive("/home") ? "text-black" : "text-white"
            } hover:font-bold hover:scale-[1.05] transition-all duration-100 text-[16px] font-[400] leading-normal tracking-[4.48px]`}
          >
            PHOTOGRAPHY
          </a>
          <a
            href="/about"
            className={`cursor-pointer font-inter ${
              isActive("/") || isActive("/home") ? "text-black" : "text-white"
            } hover:font-bold hover:scale-[1.05] transition-all duration-100 text-[16px] font-[400] leading-normal tracking-[4.48px] ${
              isActive("/about") ? "font-bold" : ""
            }`}
          >
            ABOUT
          </a>
        </div>
        <div className="lg:hidden block">
          <Hamburger
            toggled={isMobileMenuOpen}
            toggle={setIsMobileMenuOpen}
            size={25}
            color={isActive("/") || isActive("/home") ? "#000" : "#fff"}
            rounded
          />
        </div>
        <div
          className={`${
            isMobileMenuOpen ? "block" : "hidden"
          } lg:hidden absolute top-[80px] left-0 right-0 bg-black bg-opacity-50 backdrop-blur-md z-30 h-screen flex flex-col items-center`}
        >
          <div className="flex flex-col items-center gap-y-9 pt-12 font-medium">
            <a
              href="/#works"
              className={`block text-white cursor-pointer font-inter`}
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            >
              EXPERIENCE DESIGN
            </a>
            <a
              href="/graphic"
              className={`block text-white font-inter ${
                isActive("/graphic") ? "font-bold" : ""
              }`}
            >
              GRAPHIC
            </a>
            <a
              href="https://www.instagram.com/freezing_pixels/?hl=en"
              target="__blank"
              rel="noreferrer"
              className={`block text-white font-inter`}
            >
              PHOTOGRAPHY
            </a>
            <a
              href="/about"
              className={`block text-white font-inter ${
                isActive("/about") ? "font-bold" : ""
              }`}
            >
              ABOUT
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default NavBar;
