import React from "react";

function Freelance() {
  return (
    <div className="flex flex-wrap justify-center items-center max-w-[1400px] gap-[18px] sm:gap-[20px]">
      <a
        href="https://medium.com/@shreelekha-panda/cardbyte-digital-business-cards-a-product-design-casestudy-ad302162a93f"
        target="__blank"
        rel="noreferrer"
      >
        <img
          src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703764597/Portfolio/My%20Works/Cardbyte_nwxxiu.png"
          alt="Freelance-Ventures"
          className="aspect-auto w-[450px] xl:w-auto"
        />
      </a>
      <a
        href="https://www.behance.net/gallery/186417767/CYBERCOP-iOS-Cybersecurity-Application-UI-Design"
        target="__blank"
        rel="noreferrer"
      >
        <img
          src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703764598/Portfolio/My%20Works/cybercop_ragk7o.png"
          alt="Freelance-Ventures"
          className="aspect-auto w-[450px] xl:w-auto"
        />
      </a>
      <a
        href="https://www.behance.net/gallery/186858259/User-Interface-Design-for-Cryptocurrency-Application"
        target="__blank"
        rel="noreferrer"
      >
        <img
          src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703764599/Portfolio/My%20Works/SWRM-Cover_d8jlqo.png"
          alt="Freelance-Ventures"
          className="aspect-auto w-[450px] xl:w-auto"
        />
      </a>
      <a
        href="https://medium.com/@shreelekha-panda/qualitykaam-ux-case-study-on-a-platform-for-part-time-jobs-b4a55c632b96"
        target="__blank"
        rel="noreferrer"
      >
        <img
          src="https://res.cloudinary.com/dhngvdskv/image/upload/v1704319957/QualityKaam_2_tiqmyr.png"
          alt="Freelance-Ventures"
          className="aspect-auto w-[450px] xl:w-auto"
        />
      </a>
    </div>
  );
}

export default Freelance;
