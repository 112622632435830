import React, { useEffect, useState, useRef } from "react";

function Testimonials() {
  const [activeItem, setActiveItem] = useState(1);
  const carouselRef = useRef(null);

  useEffect(() => {
    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const itemId = parseInt(entry.target.id.replace("item", ""), 10);
          setActiveItem(itemId);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, {
      root: carouselRef.current,
      threshold: 0.5, // Adjust threshold as needed
    });

    const items = document.querySelectorAll(".carousel-item");
    items.forEach((item) => observer.observe(item));

    return () => {
      items.forEach((item) => observer.unobserve(item));
    };
  }, []);

  const handleDotClick = (item) => {
    const targetItem = document.getElementById(`item${item}`);
    if (targetItem) {
      const container = carouselRef.current;
      const containerRect = container.getBoundingClientRect();
      const targetRect = targetItem.getBoundingClientRect();
      const scrollLeft =
        targetRect.left - containerRect.left + container.scrollLeft;

      container.scrollTo({
        left: scrollLeft,
        behavior: "smooth",
      });

      setActiveItem(item);
    }
  };

  return (
    <div className="bg-bg-primary border-none flex flex-col gap-[13px] sm:gap-[30px] lg:gap-[87px] items-center pt-[120px]">
      <p className="font-dmSerif text-white text-[32px] lg:text-[#EAEAEA] sm:text-[48px] lg:text-[68px] hidden md:block">
        What They Say About Me
      </p>
      <p className="font-dmSerif text-white text-[32px] lg:text-[#EAEAEA] sm:text-[48px] lg:text-[68px] block md:hidden">
        Hear it from them
      </p>
      <div
        className="carousel carousel-center w-[326px] md:w-[700px] lg:w-[936px] custom-width-testimonial xl:w-[1320px] p-2 space-x-[32px]"
        ref={carouselRef}
      >
        <div
          id="item1"
          className="carousel-item items-center w-[326px] lg:w-[380px] xl:w-[418px] h-[300px] lg:h-[496px] bg-[#213266]"
        >
          <p className="p-[16px] lg:p-[32px] font-roboto text-[14px] lg:text-[16px] xl:text-[20px] font-[300] text-justify leading-[16.41px] lg:leading-[23.44px] text-white">
            I directly managed Shreelekha on a project I was volunteering. She
            has always been prompt, disciplined and participative. Her quick
            turn-around times had allowed us to fit in a lot more in the one
            month of the project than what was planned.
            <br />
            <br />~ Mohini Binepal,{" "}
            <i>
              Co-Founder & Head-Retail, Ruosh Ex. Head-Products, Kaya Skin
              Clinic
            </i>
          </p>
        </div>
        <div
          id="item2"
          className="carousel-item items-center w-[326px] lg:w-[380px] xl:w-[418px] h-[300px] lg:h-[496px] bg-[#213266]"
        >
          <p className="p-[16px] lg:p-[32px] font-roboto text-[14px] lg:text-[16px] xl:text-[20px] font-[300] text-justify leading-[16.41px] lg:leading-[23.44px] text-white">
            Shreelekha joined ID Mobile Project as UX Designer in July’21,
            Project was under heavy pressure as we were already delayed on go
            live by 4 to 5 weeks. She was able to independently deliver the work
            assigned to her with Quality and she organized UX Design reviews
            with IBM Internal and Client teams. Client has appreciated for fast
            turn around on UX Designs. She is a good listener, Has taken
            feedback in a constructive way and has incorporated the comments
            successfully. She is a good team player and provides responses in a
            timely manner. I wish her the best for her future endeavors.
            <br />
            <br />~ Murali Mynampati, <i>Project Manager, iD Mobile | IBM</i>
          </p>
        </div>
        <div
          id="item3"
          className="carousel-item items-center w-[326px] lg:w-[380px] xl:w-[418px] h-[300px] lg:h-[496px] bg-[#213266]"
        >
          <p className="p-[16px] lg:p-[32px] font-roboto text-[14px] lg:text-[16px] xl:text-[20px] font-[300] text-justify leading-[16.41px] lg:leading-[23.44px] text-white">
            Shreelekha has been the best Graphic Designer we have had over our
            journey. She was extremely fast at her work and had a sharp eye for
            detail. She worked on multiple graphics in a short period of time &
            also designed the Ul of various modules of our website. She had
            remarkable patience in cases where multiple iterations were needed.
            <br />
            <br />
            ~Dhaval Udani, <i>СЕО & Founder, danamojo; Ex-CEO, Give India</i>
          </p>
        </div>
        <div
          id="item4"
          className="carousel-item items-center w-[326px] lg:w-[380px] xl:w-[418px] h-[300px] lg:h-[496px] bg-[#213266]"
        >
          <p className="p-[16px] lg:p-[32px] font-roboto text-[14px] lg:text-[16px] xl:text-[20px] font-[300] text-justify leading-[16.41px] lg:leading-[23.44px] text-white">
            Shree is a self starter, a very confident person - who exactly
            understands business needs for her design to be as creative and at
            the same time match what the customer/client needs. She is an
            extremely good team player, knows her stuff, and can be an asset to
            any team.
            <br />
            <br />
            She is one of the most niche designers - with amazing creativity and
            design ideas for solutions that client ask.
            <br />
            <br />
            Have not seen any designer - with 1 year work exp, do so well! Kudos
            to her on this front.
            <br />
            <br />
            ~Shankalpa Mishra, <i>Business Analyst, IBM</i>
          </p>
        </div>
        <div
          id="item5"
          className="carousel-item items-center w-[326px] lg:w-[380px] xl:w-[418px] h-[300px] lg:h-[496px] bg-[#213266]"
        >
          <p className="p-[16px] lg:p-[32px] font-roboto text-[14px] lg:text-[16px] xl:text-[20px] font-[300] text-justify leading-[16.41px] lg:leading-[23.44px] text-white">
            ... always striving to improve and looking for opportunities to
            excel!
            <br />
            <br />I have had NO issues what so ever working with you and am sure
            clients have the same view! You have been quick in delivering all
            the designs for EMBER and picked up really well.
            <br />
            <br />
            To conclude, Shree has had an excellent run in the project, jelled
            well with the team and the clients, have been responsible, sincere
            towards work. Well done! Keep it up.
            <br />
            <br />~ Mohit Makhija, <i>Release Train Engineer, IBM</i>
          </p>
        </div>
      </div>
      <div className="flex justify-center w-full gap-2">
        {[1, 2, 3, 4, 5].map((item) => (
          // eslint-disable-next-line jsx-a11y/anchor-has-content
          <a
            key={item}
            onClick={() => handleDotClick(item)}
            href={`#item${item}`}
            className={`w-2 h-2 rounded ${
              activeItem === item ? "bg-[#37cdff]" : "bg-white"
            }`}
          ></a>
        ))}
      </div>
    </div>
  );
}

export default Testimonials;
