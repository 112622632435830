function Hero() {
  return (
    <div className="bg-[#FFF1E6] h-[679px] md:h-screen 2xl:h-[974px]">
      <div className="flex flex-col md:flex-row gap-[62px] md:gap-[40px] lg:gap-[80px] 2xl:gap-[144px] items-center md:justify-center h-full pt-[86px] pb-[65px] md:p-[50px]">
        <img
          src="https://res.cloudinary.com/dhngvdskv/image/upload/v1704397926/hero_1_qproh7.gif"
          alt="home-gif"
          className="aspect-square max-w-[340px] md:max-w-[350px] lg:max-w-[500px] 2xl:max-w-[677px] max-h-[340px] md:max-h-[350px] lg:max-h-[500px] 2xl:max-h-[677px]"
        />
        <div className="flex flex-col items-center md:items-start justify-center max-w-[339px] md:max-w-[480px] lg:max-w-[580px] 2xl:max-w-[700px] text-[#00134D]">
          <p className="font-dmSerif text-[54px] md:text-[58px] lg:text-[80px] 2xl:text-[100px] text-center md:text-start">Hey, you!</p>
          <p className="font-robot font-[300] text-[16px] md:text-[24px] lg:text-[28px] 2xl:text-[32px] text-center md:text-start">
            I am Shreelekha, designer, story-teller,<i className="italic"> and also a chef and a
            photographer!</i> Thanks for stopping by!
          </p>
        </div>
      </div>
    </div>
  );
}

export default Hero;
