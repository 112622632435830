import React from "react";

function Academic() {
  return (
    <div className="flex gap-[18px] sm:gap-[20px] flex-wrap justify-center items-center max-w-[1400px]">
      <a
        href="https://medium.com/@shreelekha-panda/iswitch-intelligent-switchboard-solutions-digital-product-design-casestudy-183f57c54fd1"
        target="__blank"
        rel="noreferrer"
      >
        <img
          src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703765652/Portfolio/My%20Works/iswitch_hh4qjr.png"
          alt="academic-explorations"
          className="w-[453.33px]"
        />
      </a>
      <a
        href="https://www.behance.net/gallery/186835159/Data-Storytelling-
"
        target="__blank"
        rel="noreferrer"
      >
        <img
          src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703765652/Portfolio/My%20Works/think_ksobwa.png"
          alt="academic-explorations"
          className="w-[453.33px]"
        />
      </a>
      <a
        href=" https://medium.com/@shreelekha-panda/sustainable-packaging-design-for-flavored-tea-5802b0ad77a9"
        target="__blank"
        rel="noreferrer"
      >
        <img
          src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703765652/Portfolio/My%20Works/aroma_qphptq.png"
          alt="academic-explorations"
          className="w-[453.33px]"
        />
      </a>
    </div>
  );
}

export default Academic;
