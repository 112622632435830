import React, { useState, useEffect } from "react";
import { FaArrowUp } from "react-icons/fa";


const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    setIsVisible(scrollTop > 300);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      {isVisible && (
        <div>

        <button
          onClick={scrollToTop}
          className="hidden sm:block fixed bottom-6 right-6 p-[10px] bg-[#213266] text-[#FFF1E6] rounded-full hover:text-white transition ease-in duration-300"
          >
          <FaArrowUp size={30} />
        </button>
        <button
          onClick={scrollToTop}
          className="block sm:hidden fixed bottom-6 right-6 p-[8px] bg-[#213266] text-[#FFF1E6] rounded-full hover:text-white transition ease-in duration-300"
          >
          <FaArrowUp size={15} />
        </button>
        </div>
      )}
    </div>
  );
};

export default ScrollToTopButton;
