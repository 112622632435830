import React from "react";

function AboutGallery() {
  return (
    <div className="bg-bg-primary border-none flex flex-col gap-[32px] sm:gap-[44px] lg:gap-[87px] items-center">
      <p className="font-dmSerif text-white text-[32px] lg:text-[#EAEAEA] sm:text-[48px] lg:text-[68px]">
        Life at IBM in Pictures
      </p>
      <div className="hidden md:flex flex-col gap-[8px] lg:gap-[18px]">
        <div className="flex gap-[8px] lg:gap-[18px]">
          <div className="relative image-container">
            <img
              src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703518176/Portfolio/Life%20at%20IBM/FullSizeRender_b6zgy5.png"
              alt="Life-At-IBM"
              className="aspect-auto w-[240px] lg:w-[300px] xl:w-auto"
            />
            <p className="absolute w-full bottom-0 z-50 bg-[rgba(0,0,0,0.75)] py-[6px] px-[10px] text-white font-Inter text-[10px] lg:text-[12px] leading-[14.52px] opacity-0 transition-opacity duration-300">
              At a Round Table Conference with the (Design) General Manager,
              Katrina Alcorn during IBM Design Festival{" "}
            </p>
          </div>
          <div className="relative image-container">
            <img
              src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703518176/Portfolio/Life%20at%20IBM/IMG_0415_1_wwczxb.png"
              alt="Life-At-IBM"
              className="aspect-auto w-[240px] lg:w-[300px] xl:w-auto"
            />
            <p className="absolute w-full bottom-0 z-50 bg-[rgba(0,0,0,0.75)] py-[13px] px-[10px] text-white font-Inter text-[10px] lg:text-[12px] leading-[14.52px] opacity-0 transition-opacity duration-300">
              Diwali 2023 among IBM iXers - Hosted fun activities
            </p>
          </div>
          <div className="relative image-container">
            <img
              src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703518420/Portfolio/Life%20at%20IBM/IMG_1183_1_ibgj2c.png"
              alt="Life-At-IBM"
              className="aspect-auto w-[240px] lg:w-[300px] xl:w-auto"
            />
            <p className="absolute w-full bottom-0 z-50 bg-[rgba(0,0,0,0.75)] py-[6px] px-[10px] text-white font-Inter text-[10px] lg:text-[12px] leading-[14.52px] opacity-0 transition-opacity duration-300">
              Attending a talk on Career, Design and Life by Rudi Sellers,
              Creative Director of Experience Selling Team at IBM
            </p>
          </div>
        </div>
        <div className="flex gap-[8px] lg:gap-[18px]">
          <div className="flex flex-col gap-[8px] lg:gap-[18px]">
            <div className="relative image-container">
              <img
                src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703518176/Portfolio/Life%20at%20IBM/IMG_3946_rcczs8.png"
                alt="Life-At-IBM"
                className="aspect-auto w-[240px] lg:w-[300px] xl:w-auto"
              />
              <p className="absolute w-full bottom-0 z-50 bg-[rgba(0,0,0,0.75)] py-[13px] px-[10px] text-white font-Inter text-[10px] lg:text-[12px] leading-[14.52px] opacity-0 transition-opacity duration-300">
                Diwali 2023 among IBM iXers - Hosted fun activities
              </p>
            </div>
            <div className="relative image-container">
              <img
                src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703518176/Portfolio/Life%20at%20IBM/IMG_4074_uym3xu.png"
                alt="Life-At-IBM"
                className="aspect-auto w-[240px] lg:w-[300px] xl:w-auto"
              />
              <p className="absolute w-full bottom-0 z-50 bg-[rgba(0,0,0,0.75)] py-[13px] px-[10px] text-white font-Inter text-[10px] lg:text-[12px] leading-[14.52px] opacity-0 transition-opacity duration-300">
                Independence Day 2023 among IBM iXers - Hosted fun activities
              </p>
            </div>
          </div>
          <div className="relative image-container">
            <img
              src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703518180/Portfolio/Life%20at%20IBM/IMG_5184_2_1_qgkd5b.png"
              alt="Life-At-IBM"
              className="aspect-auto w-[240px] lg:w-[300px] xl:w-auto"
            />
            <p className="absolute w-full bottom-0 z-50 bg-[rgba(0,0,0,0.75)] py-[13px] px-[10px] text-white font-Inter text-[10px] lg:text-[12px] leading-[14.52px] opacity-0 transition-opacity duration-300">
              Diwali 2023 - Performing classical song at the studio
            </p>
          </div>
          <div className="relative image-container">
            <img
              src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703518180/Portfolio/Life%20at%20IBM/IMG_5083_2_1_mfsykx.png"
              alt="Life-At-IBM"
              className="aspect-auto w-[240px] lg:w-[300px] xl:w-auto"
            />
            <p className="absolute w-full bottom-0 z-50 bg-[rgba(0,0,0,0.75)] py-[13px] px-[10px] text-white font-Inter text-[10px] lg:text-[12px] leading-[14.52px] opacity-0 transition-opacity duration-300">
              Diwali 2023 @IBM Bangalore - Hosted the event
            </p>
          </div>
        </div>
        <div className="flex gap-[8px] lg:gap-[18px]">
          <img
            src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703518180/Portfolio/Life%20at%20IBM/IMG-20230920-WA0014-01_1_ylecdu.png"
            alt="Life-At-IBM"
            className="aspect-auto w-[240px] lg:w-[300px] xl:w-auto"
          />
          <div className="flex flex-col gap-[8px] lg:gap-[18px]">
            <div className="relative image-container">
              <img
                src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703518179/Portfolio/Life%20at%20IBM/img_2143_1_djb82t.png"
                alt="Life-At-IBM"
                className="aspect-auto w-[240px] lg:w-[300px] xl:w-auto"
              />
              <p className="absolute w-full bottom-0 z-50 bg-[rgba(0,0,0,0.75)] py-[13px] px-[10px] text-white font-Inter text-[10px] lg:text-[12px] leading-[14.52px] opacity-0 transition-opacity duration-300">
                Facilitated “Design & AI” Round Table Conference
              </p>
            </div>
            <div className="relative image-container">
              <img
                src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703518179/Portfolio/Life%20at%20IBM/image_1_6_qszx79.png"
                alt="Life-At-IBM"
                className="aspect-auto w-[240px] lg:w-[300px] xl:w-auto"
              />
              <p className="absolute w-full bottom-0 z-50 bg-[rgba(0,0,0,0.75)] py-[13px] px-[10px] text-white font-Inter text-[10px] lg:text-[12px] leading-[14.52px] opacity-0 transition-opacity duration-300">
                Just a normal day at the IBM iX Studio
              </p>
            </div>
          </div>
          <div className="relative image-container">
            <img
              src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703518180/Portfolio/Life%20at%20IBM/IMG_20221209_123359_1_qfowew.png"
              alt="Life-At-IBM"
              className="aspect-auto w-[240px] lg:w-[300px] xl:w-auto"
            />
            <p className="absolute w-full bottom-0 z-50 bg-[rgba(0,0,0,0.75)] py-[13px] px-[10px] text-white font-Inter text-[10px] lg:text-[12px] leading-[14.52px] opacity-0 transition-opacity duration-300">
              First Day in office - IBM Bhubaneswar
            </p>
          </div>
        </div>
      </div>

      {/**Mobile View**/}

      <div className="md:hidden flex flex-col gap-[5px]">
        <div className="flex gap-[5px]">
          <div className="relative">
            <img
              src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703518176/Portfolio/Life%20at%20IBM/FullSizeRender_b6zgy5.png"
              alt="Life-At-IBM"
              className="aspect-auto w-[177px] sm:w-[250px]"
            />
            <p className="absolute w-full bottom-0 z-50 bg-[rgba(0,0,0,0.75)] py-[4px] px-[4.27px] text-white font-Inter text-[8px] leading-[9.68px]">
              Round Table Conf. with IBM Design GM
            </p>
          </div>
          <div className="relative">
            <img
              src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703518176/Portfolio/Life%20at%20IBM/IMG_0415_1_wwczxb.png"
              alt="Life-At-IBM"
              className="aspect-auto w-[177px] sm:w-[250px]"
            />
            <p className="absolute w-full bottom-0 z-50 bg-[rgba(0,0,0,0.75)] py-[4px] px-[4.27px] text-white font-Inter text-[8px] leading-[9.68px]">
              Hosted Diwali function @IBM Bangalore
            </p>
          </div>
        </div>
        <div className="flex gap-[5px]">
          <div className="flex flex-col gap-[5px]">
            <div className="relative">
              <img
                src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703540896/Portfolio/Life%20at%20IBM/mobile/IMG_3946_vcfwcw.png"
                alt="Life-At-IBM"
                className="aspect-auto w-[177px] sm:w-[250px]"
              />
              <p className="absolute w-full bottom-0 z-50 bg-[rgba(0,0,0,0.75)] py-[4px] px-[4.27px] text-white font-Inter text-[8px] leading-[9.68px]">
                Hosted Diwali function @IBM Bangalore
              </p>
            </div>
            <div className="relative">
              <img
                src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703540896/Portfolio/Life%20at%20IBM/mobile/IMG_4074_xt7xbd.png"
                alt="Life-At-IBM"
                className="aspect-auto w-[177px] sm:w-[250px]"
              />
              <p className="absolute w-full bottom-0 z-50 bg-[rgba(0,0,0,0.75)] py-[4px] px-[4.27px] text-white font-Inter text-[8px] leading-[9.68px]">
                Independence Day 2023 among IBMers
              </p>
            </div>
            <div className="relative">
              <img
                src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703540896/Portfolio/Life%20at%20IBM/mobile/IMG_1183_uvsu2c.png"
                alt="Life-At-IBM"
                className="aspect-auto w-[177px] sm:w-[250px]"
              />
              <p className="absolute w-full bottom-0 z-50 bg-[rgba(0,0,0,0.75)] py-[4px] px-[4.27px] text-white font-Inter text-[8px] leading-[9.68px]">
                Talk by Rudi Sellers, Creative Director
              </p>
            </div>
          </div>
          <div className="relative">
            <img
              src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703518180/Portfolio/Life%20at%20IBM/IMG_5184_2_1_qgkd5b.png"
              alt="Life-At-IBM"
              className="aspect-auto w-[177px] sm:w-[250px]"
            />
            <p className="absolute w-full bottom-0 z-50 bg-[rgba(0,0,0,0.75)] py-[4px] px-[4.27px] text-white font-Inter text-[8px] leading-[9.68px]">
              Performing classical song at the studio
            </p>
          </div>
        </div>
        <div className="flex gap-[5px]">
          <img
            src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703518180/Portfolio/Life%20at%20IBM/IMG-20230920-WA0014-01_1_ylecdu.png"
            alt="Life-At-IBM"
            className="aspect-auto w-[177px] sm:w-[250px]"
          />
          <div className="relative">
            <img
              src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703518180/Portfolio/Life%20at%20IBM/IMG_5083_2_1_mfsykx.png"
              alt="Life-At-IBM"
              className="aspect-auto w-[177px] sm:w-[250px]"
            />
            <p className="absolute w-full bottom-0 z-50 bg-[rgba(0,0,0,0.75)] py-[4px] px-[4.27px] text-white font-Inter text-[8px] leading-[9.68px]">
              Hosted Diwali function @IBM Bangalore
            </p>
          </div>
        </div>
        <div className="flex gap-[5px]">
          <div className="flex flex-col gap-[5px]">
            <div className="relative">
              <img
                src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703518179/Portfolio/Life%20at%20IBM/img_2143_1_djb82t.png"
                alt="Life-At-IBM"
                className="aspect-auto w-[177px] sm:w-[250px]"
              />
              <p className="absolute w-full bottom-0 z-50 bg-[rgba(0,0,0,0.75)] py-[4px] px-[4.27px] text-white font-Inter text-[8px] leading-[9.68px]">
                Facilitated “Design & AI” Discussion
              </p>
            </div>
            <div className="relative">
              <img
                src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703518179/Portfolio/Life%20at%20IBM/image_1_6_qszx79.png"
                alt="Life-At-IBM"
                className="aspect-auto w-[177px] sm:w-[250px]"
              />
              <p className="absolute w-full bottom-0 z-50 bg-[rgba(0,0,0,0.75)] py-[4px] px-[4.27px] text-white font-Inter text-[8px] leading-[9.68px]">
                Just a normal day at the IBM iX Studio
              </p>
            </div>
          </div>
          <div className="relative">
            <img
              src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703518180/Portfolio/Life%20at%20IBM/IMG_20221209_123359_1_qfowew.png"
              alt="Life-At-IBM"
              className="aspect-auto w-[177px] sm:w-[250px]"
            />
            <p className="absolute w-full bottom-0 z-50 bg-[rgba(0,0,0,0.75)] py-[4px] px-[4.27px] text-white font-Inter text-[8px] leading-[9.68px]">
              First Day in office - IBM Bhubaneswar
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutGallery;
