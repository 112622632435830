import React from "react";

function AboutHero() {
  return (
    <div className="relative flex justify-center">
      <div className="absolute inset-0 custom-bg-top"></div>
      <img
        src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703428052/Portfolio/1000012898-03_1_qsjrjg.png"
        alt="hero-bg-img"
        className="object-custom h-[486px] lg:h-[583px] w-full custom-bg"
      />
      <div className="absolute inset-0 bg-lightgray bg-opacity-30"></div>
      <div className="absolute mx-auto mt-[209px] lg:mt-[352px]">
        <div className="flex flex-col md:flex-row md:gap-[10px] font-dmSerif text-[32px] lg:text-[40px] text-[#F1F5FF] text-center">
          <span>Avid learner.</span>
          <span>Traveller.</span>
          <span>Music enthusiast.</span>
        </div>
        <button
          type="button"
          className="p-[8px] lg:p-[12px] rounded-[4px] border-[1px] border-white mt-[43px] lg:mt-[33px]"
        >
          <a href="https://drive.google.com/file/d/1gkl_d7o2RnIyPswnEVui5p0wG7VUzL2S/view" target="__blank" rel="noreferrer" className="font-roboto text-white tracking-[1.68px] lg:tracking-[2.4px] text-[14px] lg:text-[20px]">
            DOWNLOAD RESUME
          </a>
        </button>
      </div>
    </div>
  );
}

export default AboutHero;
