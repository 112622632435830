import Hero from "../components/hero/Hero";
import MyWorks from "../components/works/MyWorks";
import Video from "../components/video/Video";
import Testimonials from "../components/about/Testimonials";
function Home() {
  return (
    <div>
      <Hero />
      <MyWorks />
      <Video />
      <Testimonials />
    </div>
  );
}

export default Home;
