import { FaSquareInstagram, FaLinkedinIn, FaBehance } from "react-icons/fa6";
import { MdOutlineMailOutline } from "react-icons/md";

import { FaMediumM } from "react-icons/fa";

function Footer() {
  return (
    <div className="bg-bg-primary pt-[96px] pb-[63px] lg:pt-[130px] lg:pb-[132px] flex flex-col items-center text-color-primary">
      <div className="font-dmSerif text-[32px] lg:text-[68px]">
        <span>Like my work? </span>
        <span className="hidden lg:inline-block">Let’s chat.</span>
      </div>
      <div className="flex gap-[16px] text-lg mt-[18px] lg:mt-[48px]">
        <a
          href="mailto:shreelekha199@gmail.com"
          target="_blank"
          rel="noopener noreferrer"
          className="bg-[#3C4D76] h-[36px] w-[36px] rounded-[50%] flex items-center justify-center"
        >
          <MdOutlineMailOutline />
        </a>
        <a
          href="https://www.linkedin.com/in/shreelekha-panda/"
          target="__blank"
          rel="noreferrer"
          className="bg-[#3C4D76] h-[36px] w-[36px] rounded-[50%] flex items-center justify-center"
        >
          <FaLinkedinIn />
        </a>
        <a
          href="https://medium.com/@shreelekha-panda"
          target="__blank"
          rel="noreferrer"
          className="bg-[#3C4D76] h-[36px] w-[36px] rounded-[50%] flex items-center justify-center"
        >
          <FaMediumM />
        </a>
        <a
          href="https://www.behance.net/shreelekhapanda"
          target="__blank"
          rel="noreferrer"
          className="bg-[#3C4D76] h-[36px] w-[36px] rounded-[50%] flex items-center justify-center"
        >
          <FaBehance />
        </a>
        <a
          href="https://www.instagram.com/freezing_pixels/?hl=en"
          target="__blank"
          rel="noreferrer"
          className="bg-[#3C4D76] h-[36px] w-[36px] rounded-[50%] flex items-center justify-center"
        >
          <FaSquareInstagram />
        </a>
      </div>
    </div>
  );
}

export default Footer;
