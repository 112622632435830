import ReactPlayer from "react-player/youtube";

function Video() {
  return (
    <div className="bg-[#FFF1E6] pt-[30px] pb-[45px] sm:pt-[100px] sm:pb-[100px] lg:pt-[205px] lg:pb-[242px] px-[0px] lg:px-[70px]">
      <div className="max-w-[1400px] flex flex-col xl:flex-row items-center xl:items-start justify-center gap-[22px] lg:gap-[77px] mx-auto">
        <div className="rounded-lg overflow-hidden w-full max-w-[330px] sm:max-w-[400px] md:max-w-[500px] xl:max-w-[617px] xl:h-[345px] aspect-video">
          <ReactPlayer
            url="https://www.youtube.com/watch?v=sDcq1MRsLdw&ab_channel=ProAppLearnDesign"
            width="100%"
            height="100%"
          />
        </div>
        <div className="flex flex-col items-start sm:items-center xl:items-start justify-start max-w-[330px] sm:max-w-[500px] xl:max-w-[799px] gap-[12px] lg:gap-[37px] w-full">
          <p className="font-dmSerif text-[24px] sm:text-[28px] lg:text-[32px] text-color-secondary whitespace-nowrap">
            Designing for Accessibility
          </p>
          <p className="text-start sm:text-center xl:text-justify text-[12px] sm:text-[14px] lg:text-[16px] font-roboto leading-[140.625%] text-color-secondary">
            During my stint at IBM, I conducted a Masterclass session for my
            colleagues on Designing for Accessibility, with major focus on
            conducting Research for Accessibility for User Experience. Following
            the same, I conducted a two-part workshop outside IBM for the ProApp
            Community.
            <br />
            <br />
            In this workshop, which went to become one of the most successful
            sessions on ProApp, we delved into the fundamentals of Accessibility
            Research. Watch the Part 1, which focusses on defining
            accessibility, the ‘why’ and ‘how’ of conducting research for
            inclusive design, etiquettes to follow, basic Accessibility Research
            methods like Interviews, Surveys, 5 Whys, Laddering and Contextual
            Inquiry.
            <br />
            <br />
            Get the free “Accessibility Research” presentation <a href="https://docs.google.com/presentation/d/17Ez_CHGxsGfplrB686x-MDjtt7BDg0hm/edit?usp=sharing&ouid=108779370541116008998&rtpof=true&sd=true" target="__blank" rel="noreferrer" className="underline">here</a>.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Video;
