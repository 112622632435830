import { useState } from "react";
import AllWorks from "./AllWorks";
import IBM from "./IBM";
import Freelance from "./Freelance";
import Passion from "./Passion";
import Academic from "./Academic";

const tabsData = [
  { id: 1, label: "SPOTLIGHT", content: AllWorks },
  { id: 2, label: "IBM ENDEAVOURS", content: IBM },
  { id: 3, label: "FREELANCE VENTURES", content: Freelance },
  { id: 4, label: "PASSION PURSUITS", content: Passion },
  { id: 5, label: "ACADEMIC EXPLORATIONS", content: Academic },
];
function MyWorks() {
  const [activeTab, setActiveTab] = useState(tabsData[0]);
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div
      className="bg-bg-primary flex flex-col pt-[32px] lg:pt-[46px] pb-[32px] lg:pb-[173px] sm:px-0 px-[16px]"
      id="works"
    >
      <p className="text-color-primary font-dmSerif text-[32px] sm:text-[48px] lg:text-[68px]">
        My Works
      </p>
      <div className="flex flex-col items-center">
        <div className="flex items-center overflow-auto mt-[37px] mb-[18px] lg:mt-[40px] lg:mb-[40px] gap-[10px] lg:gap-[14px] sm:ml-0 w-[340px] sm:w-[600px] lg:w-[885px]">
          {tabsData.map((tab) => (
            <div
              key={tab.id}
              onClick={() => handleTabClick(tab)}
              className={`cursor-pointer p-[4px] sm:p-[6px] lg:p-[10px] rounded-[2px] sm:rounded-[4px] lg:rounded-[8px] text-white font-roboto text-[10px] sm:text-[14px] lg:text-[16px] whitespace-nowrap ${
                activeTab.id === tab.id ? "bg-[#0036A6]" : "bg-[#3E4C6E]"
              }`}
            >
              {tab.label}
            </div>
          ))}
        </div>
        <div className="mx-auto max-w-[340px] sm:max-w-none">
          <activeTab.content />
        </div>
      </div>
    </div>
  );
}

export default MyWorks;
