import AboutHero from "../components/hero/AboutHero";
import AboutMe from "../components/about/AboutMe";
import AboutGallery from "../components/about/AboutGallery";
import Testimonials from "../components/about/Testimonials";
function About() {
  return (
    <div>
      <AboutHero />
      <AboutMe/>
      <AboutGallery/>
      <Testimonials/>
    </div>
  );
}

export default About;
