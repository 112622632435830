import React from "react";

function Passion() {
  return (
    <div className="flex gap-[18px] sm:gap-[20px] flex-wrap justify-center items-center max-w-[1400px]">
      <a href="https://medium.com/@shreelekha-panda/vacayfy-a-travel-app-product-design-casestudy-8ce2b1785eb9" target="__blank" rel="noreferrer">
        <img
          src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703765215/Portfolio/My%20Works/Vacayfy_wp3xxu.png"
          alt="passion-pursuits"
          className="max-w-[340px] sm:max-w-[453.33px]"
        />
      </a>
      <a href="https://medium.com/@shreelekha-panda/designing-amazon-for-ftue-first-time-user-experience-68f677b1aa0f" target="__blank" rel="noreferrer">
        <img
          src="https://res.cloudinary.com/dhngvdskv/image/upload/v1704320195/Amazon_1_frfwow.png"
          alt="passion-pursuits"
          className="max-w-[340px] sm:max-w-[453.33px]"
        />
      </a>
      <a href="https://www.behance.net/gallery/113842451/UI-Designing-for-Hot-air-Balloon-Mobile-Application" target="__blank" rel="noreferrer">
        <img
          src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703765218/Portfolio/My%20Works/Quovantis_hip21u.png"
          alt="passion-pursuits"
          className="max-w-[340px] sm:max-w-[453.33px]"
        />
      </a>
      <a href="https://www.behance.net/gallery/186859835/UI-Designing-for-At-home-Medical-Examinations" target="__blank" rel="noreferrer">
        <img
          src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703765214/Portfolio/My%20Works/Gainsight_f7vzmu.png"
          alt="passion-pursuits"
          className="max-w-[340px] sm:max-w-[453.33px]"
        />
      </a>
      <a href="https://medium.com/@shreelekha-panda/inaya-your-lifes-a-blessing-8594ba355fa5" target="__blank" rel="noreferrer">
        <img
          src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703765215/Portfolio/My%20Works/Inaya_nytydn.png"
          alt="passion-pursuits"
          className="max-w-[340px] sm:max-w-[453.33px]"
        />
      </a>
      <a href="https://medium.com/@shreelekha-panda/home-security-system-app-design-7558948bc111" target="__blank" rel="noreferrer">
        <img
          src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703765215/Portfolio/My%20Works/Home_Security_atqyyq.png"
          alt="passion-pursuits"
          className="max-w-[340px] sm:max-w-[453.33px]"
        />
      </a>
    </div>
  );
}

export default Passion;
