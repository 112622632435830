import React from "react";

function IBM() {
  return (
    <>
      <div className="flex flex-wrap flex-col sm:flex-row justify-center items-center gap-[18px] lg:gap-[10px] xl:gap-[20px]">
        <a
          href="https://medium.com/@shreelekha-panda/ember-designing-enterprise-user-experience-for-the-energy-industry-fc5944ba0a44"
          target="__blank"
          rel="noreferrer"
        >
          <img
            src="https://res.cloudinary.com/dhngvdskv/image/upload/v1704838043/Ember_3_edj620.png"
            alt="IBM-Endeavours"
            className="aspect-auto lg:w-[450px] xl:w-auto"
          />
        </a>
        <a
          href="https://shreelekha-panda.medium.com/vault-designing-experience-for-secure-content-management-ec1e109ba2b0"
          target="__blank"
          rel="noreferrer"
        >
          <img
            src="https://res.cloudinary.com/dhngvdskv/image/upload/v1704838044/Ember_2_rtxaqe.png"
            alt="IBM-Endeavours"
            className="aspect-auto lg:w-[450px] xl:w-auto"
          />
        </a>
        <a
          href="https://www.behance.net/gallery/185334867/Digitisation-of-Healthcare-for-Elderly-during-pandemic"
          target="__blank"
          rel="noreferrer"
        >
          <img
            src="https://res.cloudinary.com/dhngvdskv/image/upload/v1704838041/Ember_4_y4vrxx.png"
            alt="IBM-Endeavours"
            className="aspect-auto lg:w-[450px] xl:w-auto"
          />
        </a>
      </div>
    </>
  );
}

export default IBM;
