import GraphicHero from "../components/hero/GraphicHero"
import Gallery from "../components/graphic/Gallery"
function Graphic() {
  return (
    <div>
      <GraphicHero/>
      <Gallery/>
    </div>
  )
}

export default Graphic