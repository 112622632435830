import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Home from "./pages/Home";
import About from "./pages/About";
import Graphic from "./pages/Graphic";

import NavBar from "./components/marginals/navbar/NavBar";
import Footer from "./components/marginals/footer/Footer";
import ScrollToTopButtom from "./components/marginals/scroll/ScrollToTopButton";

function App() {
  return (
    <div className="text-center overflow-x-hidden">
      <Router>
        <NavBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/graphic" element={<Graphic />} />
        </Routes>
        <ScrollToTopButtom />
        <Footer />
      </Router>
    </div>
  );
}

export default App;
