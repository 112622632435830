import React from "react";

function Gallery() {
  return (
    <div className="bg-bg-primary flex flex-col items-center pt-[36px] sm:pt-[54px] lg:pt-[132px]">
      <div className="hidden sm:flex flex-col gap-[10px] xl:gap-[18px] max-w-[1400px]">
        <div className="flex gap-[10px] xl:gap-[20px]">
          <a
            href="https://www.behance.net/gallery/186772035/Designing-Logo-for-The-Binge-Town-Private-Theatre"
            target="__blank"
            rel="noreferrer"
          >
            <img
              src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703623033/Portfolio/Graphics/Cover-Binge_town_uwtg6k.jpg"
              alt="my-graphic"
              className="aspect-auto w-[250px] xl:w-[500px] 2xl:w-auto object-contain"
            />
          </a>
          <a
            href="https://www.behance.net/gallery/186771623/Logo-Design-for-Mentors4India"
            target="__blank"
            rel="noreferrer"
          >
            <img
              src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703623074/Portfolio/Graphics/Frame_57_svy8fj.png"
              alt="my-graphic"
              className="aspect-auto w-[250px] xl:w-[500px] 2xl:w-auto object-contain"
            />
          </a>
        </div>
        <div className="flex gap-[10px] xl:gap-[18px]">
          <img
            src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703623071/Portfolio/Graphics/1_8933_k6qdkr.png"
            alt="my-graphic"
            className="aspect-auto w-[120px] xl:w-[240px] 2xl:w-auto object-contain"
          />
          <img
            src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703623069/Portfolio/Graphics/3_1_c97qwl.png"
            alt="my-graphic"
            className="aspect-auto w-[120px] xl:w-[240px] 2xl:w-auto object-contain"
          />
          <img
            src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703623069/Portfolio/Graphics/4_1_xg2gsb.png"
            alt="my-graphic"
            className="aspect-auto w-[120px] xl:w-[240px] 2xl:w-auto object-contain"
          />
          <img
            src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703623071/Portfolio/Graphics/2_21_bdcchk.png"
            alt="my-graphic"
            className="aspect-auto w-[120px] xl:w-[240px] 2xl:w-auto object-contain"
          />
        </div>
        <div className="flex gap-[10px] xl:gap-[18px]">
          <img
            src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703623078/Portfolio/Graphics/sezy_bxzfpp.png"
            alt="my-graphic"
            className="aspect-auto w-[163px] xl:w-[325px] 2xl:w-auto"
          />
          <img
            src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703623043/Portfolio/Graphics/komfy_i6zhth.png"
            alt="my-graphic"
            className="aspect-auto w-[163px] xl:w-[325px] 2xl:w-auto"
          />
          <img
            src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703623071/Portfolio/Graphics/book5_bksngv.png"
            alt="my-graphic"
            className="aspect-auto w-[163px] xl:w-[325px] 2xl:w-auto"
          />
        </div>
        <div className="flex gap-[10px] xl:gap-[20px] 2xl:gap-[23px]">
          <img
            src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703623051/Portfolio/Graphics/book4_olhdnv.png"
            alt="my-graphic"
            className="aspect-auto w-[340px] xl:w-[680px] 2xl:w-auto"
          />
          <img
            src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703623048/Portfolio/Graphics/book1_pzsh0n.png"
            alt="my-graphic"
            className="aspect-auto w-[160px] xl:w-[310px] 2xl:w-auto"
          />
        </div>
        <div className="flex gap-[10px] xl:gap-[18px]">
          <div className="flex flex-col">
            <img
              src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703623042/Portfolio/Graphics/art1_np8oth.png"
              alt="my-graphic"
              className="aspect-auto w-[190px] xl:w-[380px] 2xl:w-auto"
            />
            <img
              src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703623038/Portfolio/Graphics/art2_k23exg.png"
              alt="my-graphic"
              className="aspect-auto w-[190px] xl:w-[380px] 2xl:w-auto"
            />
          </div>
          <img
            src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703623047/Portfolio/Graphics/neon_osrylj.png"
            alt="my-graphic"
            className="aspect-auto w-[145px] xl:w-[290px] 2xl:w-auto"
          />
          <div className="flex flex-col gap-[10px] xl:gap-[18px]">
            <img
              src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703623035/Portfolio/Graphics/gold_dejl23.png"
              alt="my-graphic"
              className="aspect-auto w-[150px] xl:w-[300px] 2xl:w-auto"
            />
            <img
              src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703623041/Portfolio/Graphics/whlogo_bsx80k.png"
              alt="my-graphic"
              className="aspect-auto w-[150px] xl:w-[300px] 2xl:w-auto"
            />
          </div>
        </div>
        <div className="flex gap-[10px] xl:gap-[16px] 2xl:gap-[24px]">
          <img
            src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703623040/Portfolio/Graphics/Screenshot_2023-12-14_at_9.16_1_ikyxr4.png"
            alt="my-graphic"
            className="aspect-auto w-[120px] xl:w-[240px] 2xl:w-auto"
          />
          <img
            src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703623070/Portfolio/Graphics/Screenshot_2023-12-14_at_9.14_1_rlgwvk.png"
            alt="my-graphic"
            className="aspect-auto w-[120px] xl:w-[240px] 2xl:w-auto"
          />
          <img
            src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703623050/Portfolio/Graphics/Screenshot_2023-12-14_at_9.15_1_ivrokt.png"
            alt="my-graphic"
            className="aspect-auto w-[120px] xl:w-[240px] 2xl:w-auto"
          />
          <img
            src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703623044/Portfolio/Graphics/Screenshot_2023-12-14_at_9.18_1_bye5gx.png"
            alt="my-graphic"
            className="aspect-auto w-[120px] xl:w-[240px] 2xl:w-auto"
          />
        </div>
        <div className="flex gap-[10px] xl:gap-[17px]">
          <img
            src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703623035/Portfolio/Graphics/Frame_61_uybvfz.png"
            alt="my-graphic"
            className="aspect-auto w-[162px] xl:w-[325px] 2xl:w-auto"
          />
          <img
            src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703684762/Portfolio/Graphics/Frame_24_t7ak2y.png"
            alt="my-graphic"
            className="aspect-auto w-[162px] xl:w-[325px] 2xl:w-auto"
          />
          <div className="flex flex-col gap-[10px] xl:gap-[18px]">
            <img
              src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703623033/Portfolio/Graphics/Slide24_kmm25x.png"
              alt="my-graphic"
              className="aspect-auto w-[165px] xl:w-[325px] 2xl:w-auto"
            />
            <img
              src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703623034/Portfolio/Graphics/Slide25_ylbcmd.png"
              alt="my-graphic"
              className="aspect-auto w-[165px] xl:w-[325px] 2xl:w-auto"
            />
          </div>
        </div>
        <div className="flex gap-[10px] xl:gap-[18px]">
          <img
            src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703623075/Portfolio/Graphics/IMG-20230918-WA0003_nrznxo.png"
            alt="my-graphic"
            className="aspect-auto w-[163px] xl:w-[325px] 2xl:w-auto"
          />
          <img
            src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703623045/Portfolio/Graphics/IMG-20230906-WA0007_rkeecw.png"
            alt="my-graphic"
            className="aspect-auto w-[163px] xl:w-[325px] 2xl:w-auto"
          />
          <img
            src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703623050/Portfolio/Graphics/IMG_20230902_213855_avp7h4.png"
            alt="my-graphic"
            className="aspect-auto w-[163px] xl:w-[325px] 2xl:w-auto"
          />
        </div>
        <div className="flex gap-[10px] xl:gap-[16px] 2xl:gap-[18px]">
          <img
            src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703623037/Portfolio/Graphics/Slide10_qdkypr.png"
            alt="my-graphic"
            className="aspect-auto w-[250px] xl:w-[500px] 2xl:w-auto"
          />
          <img
            src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703623039/Portfolio/Graphics/Slide11_mvu4cd.png"
            alt="my-graphic"
            className="aspect-auto w-[250px] xl:w-[500px] 2xl:w-auto"
          />
        </div>
        <div className="flex gap-[10px] xl:gap-[20px]">
          <div className="flex flex-col gap-[10px] xl:gap-[18px]">
            <img
              src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703623033/Portfolio/Graphics/Slide18_pqxlkz.png"
              alt="my-graphic"
              className="aspect-auto w-[250px] xl:w-[500px] 2xl:w-auto"
            />
            <img
              src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703623033/Portfolio/Graphics/Slide19_l188dy.png"
              alt="my-grapic"
              className="aspect-auto w-[250px] xl:w-[500px] 2xl:w-auto"
            />
          </div>
          <img
            src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703623034/Portfolio/Graphics/Slide22_1_kbeute.png"
            alt="my-graphic"
            className="aspect-auto w-[250px] xl:w-[500px] 2xl:w-auto"
          />
        </div>
      </div>
      {/* Mobile View */}
      <div className="sm:hidden flex flex-col items-center gap-[18px] max-w-[338px]">
        <div className="flex flex-col gap-[18px]">
          <a
            href="https://www.behance.net/gallery/186772035/Designing-Logo-for-The-Binge-Town-Private-Theatre"
            target="__blank"
            rel="noreferrer"
          >
            <img
              src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703623033/Portfolio/Graphics/Cover-Binge_town_uwtg6k.jpg"
              alt="my-graphic"
              className="aspect-auto w-[338px]"
            />
          </a>
          <a
            href="https://www.behance.net/gallery/186771623/Logo-Design-for-Mentors4India"
            target="__blank"
            rel="noreferrer"
          >
            <img
              src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703623074/Portfolio/Graphics/Frame_57_svy8fj.png"
              alt="my-graphic"
              className="aspect-auto w-[338px]"
            />
          </a>
        </div>
        <div className="flex flex-wrap gap-[8px] w-[338px]">
          <img
            src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703623071/Portfolio/Graphics/1_8933_k6qdkr.png"
            alt="my-graphic"
            className="aspect-auto w-[165px]"
          />
          <img
            src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703623069/Portfolio/Graphics/3_1_c97qwl.png"
            alt="my-graphic"
            className="aspect-auto w-[165px]"
          />
          <img
            src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703623069/Portfolio/Graphics/4_1_xg2gsb.png"
            alt="my-graphic"
            className="aspect-auto w-[165px]"
          />
          <img
            src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703623071/Portfolio/Graphics/2_21_bdcchk.png"
            alt="my-graphic"
            className="aspect-auto w-[165px]"
          />
        </div>
        <div className="flex flex-wrap w-[338px] gap-[8px]">
          <img
            src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703623078/Portfolio/Graphics/sezy_bxzfpp.png"
            alt="my-graphic"
            className="aspect-auto w-[165px]"
          />
          <img
            src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703623043/Portfolio/Graphics/komfy_i6zhth.png"
            alt="my-graphic"
            className="aspect-auto w-[165px]"
          />
          <img
            src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703623048/Portfolio/Graphics/book1_pzsh0n.png"
            alt="my-graphic"
            className="aspect-auto w-[165px]"
          />
          <img
            src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703623071/Portfolio/Graphics/book5_bksngv.png"
            alt="my-graphic"
            className="aspect-auto w-[165px]"
          />
        </div>
        <div className="flex gap-[8px]">
          <img
            src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703623051/Portfolio/Graphics/book4_olhdnv.png"
            alt="my-graphic"
            className="aspect-auto w-[165px]"
          />
          <img
            src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703708559/Portfolio/Graphics/neon_1_gaagxd.png"
            alt="my-graphic"
            className="aspect-auto w-[165px]"
          />
        </div>
        <div className="flex gap-[8px]">
          <div className="flex flex-col">
            <img
              src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703623042/Portfolio/Graphics/art1_np8oth.png"
              alt="my-graphic"
              className="aspect-auto w-[181px]"
            />
            <img
              src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703623038/Portfolio/Graphics/art2_k23exg.png"
              alt="my-graphic"
              className="aspect-auto w-[181px]"
            />
          </div>
          <div className="flex flex-col gap-[7px]">
            <img
              src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703623035/Portfolio/Graphics/gold_dejl23.png"
              alt="my-graphic"
              className="aspect-auto w-[142px]"
            />
            <img
              src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703623041/Portfolio/Graphics/whlogo_bsx80k.png"
              alt="my-graphic"
              className="aspect-auto w-[142px]"
            />
          </div>
        </div>
        <div className="flex flex-wrap gap-[6px] max-w-[338px]">
          <img
            src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703623040/Portfolio/Graphics/Screenshot_2023-12-14_at_9.16_1_ikyxr4.png"
            alt="my-graphic"
            className="aspect-auto w-[166px]"
          />
          <img
            src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703623070/Portfolio/Graphics/Screenshot_2023-12-14_at_9.14_1_rlgwvk.png"
            alt="my-graphic"
            className="aspect-auto w-[166px]"
          />
          <img
            src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703623050/Portfolio/Graphics/Screenshot_2023-12-14_at_9.15_1_ivrokt.png"
            alt="my-graphic"
            className="aspect-auto w-[166px]"
          />
          <img
            src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703623044/Portfolio/Graphics/Screenshot_2023-12-14_at_9.18_1_bye5gx.png"
            alt="my-graphic"
            className="aspect-auto w-[166px]"
          />
        </div>
        <div className="flex gap-[6px]">
          <img
            src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703623035/Portfolio/Graphics/Frame_61_uybvfz.png"
            alt="my-graphic"
            className="aspect-auto w-[166px]"
          />
          <img
            src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703684762/Portfolio/Graphics/Frame_24_t7ak2y.png"
            alt="my-graphic"
            className="aspect-auto w-[166px]"
          />
        </div>
        <div className="flex gap-[6px]">
          <img
            src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703623033/Portfolio/Graphics/Slide24_kmm25x.png"
            alt="my-graphic"
            className="aspect-auto w-[166px]"
          />
          <img
            src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703623034/Portfolio/Graphics/Slide25_ylbcmd.png"
            alt="my-graphic"
            className="aspect-auto w-[166px]"
          />
        </div>
        <div>
          <img
            src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703623075/Portfolio/Graphics/IMG-20230918-WA0003_nrznxo.png"
            alt="my-graphic"
            className="aspect-auto w-[338px]"
          />
        </div>
        <div>
          <img
            src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703623045/Portfolio/Graphics/IMG-20230906-WA0007_rkeecw.png"
            alt="my-graphic"
            className="aspect-auto w-[338px]"
          />
        </div>
        <div>
          <img
            src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703623050/Portfolio/Graphics/IMG_20230902_213855_avp7h4.png"
            alt="my-graphic"
            className="aspect-auto w-[338px]"
          />
        </div>
        <div>
          <img
            src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703623037/Portfolio/Graphics/Slide10_qdkypr.png"
            alt="my-graphic"
            className="aspect-auto w-[338px]"
          />
        </div>
        <div>
          <img
            src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703623039/Portfolio/Graphics/Slide11_mvu4cd.png"
            alt="my-graphic"
            className="aspect-auto w-[338px]"
          />
        </div>
        <div className="flex gap-[6px]">
          <img
            src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703623033/Portfolio/Graphics/Slide18_pqxlkz.png"
            alt="my-graphic"
            className="aspect-auto w-[166px]"
          />
          <img
            src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703623033/Portfolio/Graphics/Slide19_l188dy.png"
            alt="my-grapic"
            className="aspect-auto w-[166px]"
          />
        </div>
        <div>
          <img
            src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703623034/Portfolio/Graphics/Slide22_1_kbeute.png"
            alt="my-graphic"
            className="aspect-auto w-[338px]"
          />
        </div>
      </div>
    </div>
  );
}

export default Gallery;
