import React from "react";

function AboutMe() {
  return (
    <div className="bg-bg-primary relative flex items-center justify-center sm:justify-normal pt-[33px] sm:pt-[100px] lg:pt-[166px] pb-[627px] sm:pb-[400px] md:pb-[450px] lg:pb-[134px] sm:pl-[10%] md:pl-[18%] lg:p-[10%] xl:pl-[15%] 2xl:pl-[13.39%]">
      <img
        src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703455289/Portfolio/PXL_20230829_152608386_1_rfpdtw.png"
        alt="pic"
        className="max-h-[327px] lg:max-h-[500px] xl:max-h-[650px] 2xl:max-h-[821px] aspect-square"
      />
      <div className="absolute flex flex-col top-[254px] lg:top-auto right-0 sm:right-auto gap-[18px] 2xl:gap-[36px] items-start w-[320px] sm:w-[380px] md:w-[420px] lg:w-[500px] xl:w-[550px] xl:h-[550px] 2xl:w-[692px] 2xl:h-[687px] bg-[#213266] py-[32px] 2xl:py-[54px] px-[32px] lg:px-[40px] 2xl:px-[64px] translate-y-[0] sm:translate-y-0 translate-x-0 sm:translate-x-[200px] lg:translate-x-[350px] xl:translate-x-[520px] 2xl:translate-x-[620px]">
        <p className="font-dmSerif text-[24px] lg:text-[40px] text-[#F1F5FF]">
          About Me
        </p>
        <p className="text-justify text-[#F1F5FF] font-roboto text-[14px] lg:text-[16px] 2xl:text-[20px] leading-[140.625%]">
          An avid learner, reader plus a fitness, music and travel enthusiast.
          On the workfront, a Service Design fanatic and UX practitioner at IBM,
          with a Bachelor's degree in Industrial Design from National Institute
          of Technology, Rourkela. I mentor wanna-be UXers and young graduates
          on ADPlist.org. I also share my expertise on design and tech
          communities like ProApp. I am amused by personal finance,
          revolutionary technology and I have experience of working in different
          industries on freelance and part-time international projects.
          <br />
          <br />I am a people’s person. I am empathetic towards my team-members
          and I deeply care about people who are close to me. I love anchoring
          and organising events. I am an ardent music enthusiast, I've performed
          across various genres during my time at IBM. When I’m free, I love to
          travel and escape in the laps of nature. I also love photography -
          clicking and editing photos is my hobby. I am trying to amp up my
          content-creation game currently{" "}
          <a
            href="https://www.instagram.com/freezing_pixels/?hl=en"
            target="__blank"
            rel="noreferrer"
          >
            @freezing_pixels
          </a>{" "}
          on Instagram.
        </p>
      </div>
    </div>
  );
}

export default AboutMe;
