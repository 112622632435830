import React from "react";

function AllWorks() {
  return (
    <div className="flex flex-wrap flex-col sm:flex-row justify-center items-center gap-[18px] lg:gap-[10px] xl:gap-[20px]">
      <a
        href="https://shreelekha-panda.medium.com/vault-designing-experience-for-secure-content-management-ec1e109ba2b0"
        target="__blank"
        rel="noreferrer"
      >
        <img
          src="https://res.cloudinary.com/dhngvdskv/image/upload/v1704784100/Ember_1_m6wjec.png"
          alt="IBM-Endeavours"
          className="aspect-auto"
        />
      </a>
      <a
        href="https://medium.com/@shreelekha-panda/designing-amazon-for-ftue-first-time-user-experience-68f677b1aa0f"
        target="__blank"
        rel="noreferrer"
      >
        <img
          src="https://res.cloudinary.com/dhngvdskv/image/upload/v1704784101/Amazon_2_zmlxya.png"
          alt="passion-pursuits"
          className=""
        />
      </a>
      <a
        href="https://medium.com/@shreelekha-panda/qualitykaam-ux-case-study-on-a-platform-for-part-time-jobs-b4a55c632b96"
        target="__blank"
        rel="noreferrer"
      >
        <img
          src="https://res.cloudinary.com/dhngvdskv/image/upload/v1704784102/QualityKaam_3_xw9crc.png"
          alt="Freelance-Ventures"
          className=""
        />
      </a>
    </div>
  );
}

export default AllWorks;
