import React from "react";

function GraphicHero() {
  return (
    <div className="relative flex justify-center">
      <div className="absolute inset-0 custom-bg-top"></div>
      <img
        src="https://res.cloudinary.com/dhngvdskv/image/upload/v1703428054/Portfolio/IMG20231112181837_1_r2xwfc.png"
        alt="hero-bg-img"
        className="object-custom-2 h-[486px] lg:h-[583px] w-full custom-bg"
      />
      <div className="absolute inset-0 bg-lightgray bg-opacity-30"></div>
      <div className="absolute mx-auto mt-[209px] lg:mt-[352px]"></div>
      <div className="absolute mx-auto mt-[209px] lg:mt-[352px]">
        <div className="flex flex-col md:flex-row md:gap-[10px] font-dmSerif text-[32px] lg:text-[40px] text-[#F1F5FF] text-center">
          <p className="max-w-[330px] sm:max-w-[500px] lg:max-w-[826px]">I immerse myself in Art, Decor, Music & Movies when I’m not working.</p>
        </div>
      </div>
    </div>
  );
}

export default GraphicHero;
